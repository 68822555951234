import { CarouselComponent } from './../components/carousel/carousel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { ResponseMessageComponent } from '../components/response-message/response-message.component';
import { SortDirective } from '../directives/sort.directive';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { CarouselModule } from 'primeng/carousel';
import { AvatarModule } from 'primeng/avatar';
import { CopyComponent } from '../components/copy/copy.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ScrollZindexDirective } from '../directives/scrollZindex.directive';
const ComponentsArray = [
  ButtonModule,
  TableModule,
  BadgeModule,
  DragDropModule,
  InputTextModule,
  CascadeSelectModule,
  CalendarModule,
  FileUploadModule,
  ImageModule,
  CarouselModule,
  AvatarModule,
  ClipboardModule,
  ToastModule,
  AutoCompleteModule
];
@NgModule({
  declarations: [
    SortDirective,
    ResponseMessageComponent,
    CarouselComponent,
    CopyComponent,
    ScrollZindexDirective
  ],
  imports: [ComponentsArray],
  exports: [
    SortDirective,
    ScrollZindexDirective,
    ResponseMessageComponent,
    ComponentsArray,
    CarouselComponent,
    CopyComponent,
  ],
})
export class SharedModule {}
