import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-work',
  templateUrl: './team-work.component.html',
  styleUrls: ['./team-work.component.scss'],
})
export class TeamWorkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
