import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { Observable, catchError } from 'rxjs';
import { ICheckoutPaymentRequest, ICheckoutRequest, ICheckoutResponse, IOrderStatus } from '../models/checkout';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private apiURL = `${env.apiUrl}checkout`;

  constructor(private http: HttpClient) { }

  public walletCheckout(order: ICheckoutRequest): Observable<IOrderStatus> {
    return this.http
      .post<IOrderStatus>(this.apiURL, order, { withCredentials: true })
      .pipe(
        catchError((err) => {
          console.log(err)
          throw new Error(err.error.message);
        })
      );
  }
  public checkout(order: ICheckoutRequest): Observable<ICheckoutResponse> {
    return this.http
      .post<ICheckoutResponse>(this.apiURL, order, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  public checkoutUserWallet(order: ICheckoutPaymentRequest): Observable<any> {
    return this.http
      .post<any>(`${this.apiURL}/charge-user-wallet`, order, { withCredentials: true })
      // .pipe(
      //   catchError((err) => {
      //     throw new Error(err);
      //   })
      // );
  }

  public completeOrder(order: string): Observable<any> {
    return this.http.patch<any>(
      `${this.apiURL}/complete-order/${order}`,
      null,
      { withCredentials: true }
    );
  }

  public returnOrder(order: string): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}/return-order/${order}`, null, {
      withCredentials: true,
    });
  }
}
