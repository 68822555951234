import { Subscription } from 'rxjs';
import { OrderStatus } from 'projects/shared/models/order.constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TicketStatus } from 'projects/shared/models/enums/ticket-system-status.enums';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { environment } from '../environments/environment.prod';
import { AuthService } from 'projects/shared/services/auth.service';
import { TicketSystemService } from 'projects/shared/services/ticket-system.service';
import { OrderService } from 'projects/shared/services/order.service';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isVisible: boolean = false;
  showHeader = true;
  orderStatus = OrderStatus;
  ticketStatus = TicketStatus;
  processingOrders!: number;
  opendTickets!: number;
  user!: StaffLoginResponse;
  subscription: Subscription = new Subscription();
  public admin_email: string = environment.admin_email;
  toggleSidebare: boolean = true;
  constructor(
    private orderServ: OrderService,
    private ticketService: TicketSystemService,
    private storeChangesServ: StoreChangesService,
    private router: Router,
    private authServ: AuthService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const basePath = event.urlAfterRedirects.split('?')[0];
        this.showHeader = [
          '/auth/login',
          '/auth/register',
          '/auth/otp',
          '/auth/forget-password',
          '/auth/creatAccountToStaffMember',
          '/auth/reset',].includes(basePath);
      }

    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (document.querySelector('.layout-setting')) console.log(123);
    }, 1000);
    this.validate();
    this.subscription.add(
      this.storeChangesServ.getUserAdmin().subscribe({
        next: (response) => {
          this.user = response;
        },
      })
    );
    if (this.user) {
      this.getTickets();
      this.getOrders();
    }
  }


  toggleSidebareEvent(event: any): void {
    this.toggleSidebare = event;
  }

  validate(): void {
    this.subscription.add(
      this.authServ.validateAdmin().subscribe({
        next: (response) => {
          this.user = response;
          this.storeChangesServ.setUser(response);
        },
      })
    );
  }

  getOrders() {
    this.orderServ.countOrders().subscribe((response) => {
      response.map((ele) => {
        ele._id === this.orderStatus.processing
          ? (this.processingOrders = ele.count!)
          : '';
      });
    });
  }
  getTickets() {
    this.ticketService.getTickets({ status: 'Open' }).subscribe((response) => {
      this.opendTickets = response.total;
    });
  }
  public canCreate(): boolean {
    return !this.user || (this.user && this.user?.email === this.admin_email);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
