<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="back text-start py-2" [routerLink]="['/panel/sliders-center']">
        <i class="pi pi-arrow-left"></i>
        Back
      </div>
      <h3>Partners Slider Photos.</h3>
    </div>
    <div class="col-12 col-md-8">
      <div class="card p-5">
        <h6 class="text-right text-danger">Width = 250px * Hight = 100px !</h6>
        <form>
          <div class="form-group">
            <label for="controlFile1">Example file input</label>
            <input type="file" class="form-control-file" id="controlFile1" />
          </div>
          <button type="submit" class="btn btn-primary mb-2 float-right">
            <i class="bi bi-plus"></i> ADD
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="card p-1">
        <img src="../../../../assets/logo-web-2.png" class="card-img-top" alt="..." />
        <div class="card-body">
          <p class="card-text">text</p>
          <div class="d-flex justify-content-between">
            <a class="btn btn-success"><i class="pi pi-eye"></i>
            </a>

            <a class="btn btn-danger"><i class="pi pi-trash-can"></i><i class="bi bi-archive-fill"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
