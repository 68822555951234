import { Injectable } from '@angular/core';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  userData = new BehaviorSubject<StaffLoginResponse>({} as StaffLoginResponse)
  avatarLoader = new BehaviorSubject<boolean>(false)

  constructor() { }
}
