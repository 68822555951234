import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { IWallet, IMovements } from '../models/interfaces/wallet.model';
import { IResponse } from '../models/interfaces/response.model';
import { Order } from '../models/order';
@Injectable({
  providedIn: 'root',
})
export class WalletService {
  apiURL = `${env.apiUrl}wallets/`;

  constructor(private http: HttpClient) { }
  createWallet(wallet: IWallet | any): Observable<IWallet> {
    return this.http
      .post<IWallet>(this.apiURL, wallet, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  /*=================================ADMIN============================================*/
  adminTopUp(payload:{}, walletId: string): Observable<IWallet> {
    return this.http.post<IWallet>(
      `${this.apiURL}admin-top-up/${walletId}`, payload ,
      // { amount: amount },
      { withCredentials: true }
    );
  }
  getWallets(param: {} = {}): Observable<IResponse<IWallet>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IWallet>>(
      `${this.apiURL}admin-wallets?${params.toString()}`,
      { withCredentials: true }
    );
  }
  getwalletById(walletId: string): Observable<IWallet> {
    return this.http.get<IWallet>(`${this.apiURL}admin-wallets/${walletId}`, {
      withCredentials: true,
    });
  }
  getMovements(walletId: string, param: {} = {}): Observable<IResponse<IMovements>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IMovements>>(
      `${this.apiURL}admin-wallets/${walletId}/movments?${params}`,
      { withCredentials: true }
    );
  }

  /*=================================USER============================================*/
  getUserWallets(): Observable<IWallet[]> {
    return this.http.get<IWallet[]>(`${this.apiURL}user-wallets`, {
      withCredentials: true,
    });
  }
  getUserWalletById(walletId: string): Observable<IWallet> {
    return this.http.get<IWallet>(`${this.apiURL}user-wallets/${walletId}`, {
      withCredentials: true,
    });
  }
  getUserMovements(walletId: string, param: {} = {}): Observable<IResponse<IWallet>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<IWallet>>(
      `${this.apiURL}user-wallets/${walletId}/movments?${params}`,
      { withCredentials: true }
    );
  }

  setDefaultWallets(walletId: string): Observable<any> {
    return this.http.patch<any>(
      `${this.apiURL}user-wallets/${walletId}/set-default`,
      { withCredentials: true }
    );
  }

  /*=================================TopUpList===========================================*/
  getTopUp(param: {} = {}): Observable<any> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<any>(`${this.apiURL}admin-top-up-order?${params}`, {
      withCredentials: true,
    });
  }
  countTopUps(queryParam = ''): Observable<any> {
    // return this.http.get<any>(`${this.apiURL}admin-top-up-order/status-count/?email=${queryParam}`, {
    return this.http.get<any>(`${this.apiURL}admin-top-up-order/status-count`, {
      withCredentials: true,
    });
  }
  getOneTopUp(Id: string): Observable<any> {
    return this.http.get<any>(`${this.apiURL}admin-top-up-order/${Id}`, {
      withCredentials: true,
    });
  }
  updatetopUp(topUp: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}admin-top-up-order/${topUp._id}`, topUp, {
      withCredentials: true,
    });
  }
  updateTopUsStatus(topupId: any, status: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}admin-top-up-order/${topupId}/status`, status, {
      withCredentials: true,
    });
  }
  // /admin-top-up-order/66f17e2561692e74a7c73a8e/status
  deletetopUp(topUpId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiURL}admin-top-up-order/${topUpId}`, {
      withCredentials: true,
    });
  }
}
