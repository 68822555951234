export const ORDER_STATUS = [
  {
    label: 'Pending',
    color: 'primary'
  },
  {
    label: 'Processing',
    color: 'warning'
  },
  {
    label: 'On holed',
    color: 'warning'
  },
  {
    label: 'Compeleted',
    color: 'success'
  },
  {
    label: 'Failed',
    color: 'danger'
  },
  {
    label: 'Canceled',
    color: 'danger'
  },

  {
    label: 'Returned',
    color: 'danger'
  },
];
export enum OrderStatus {
  pending = 'Pending',
  processing = 'Processing',
  onHold = 'OnHold',
  failed = 'Failed',
  compeleted = 'Compeleted',
  canceled = 'Canceled',
  returned = 'Returned',
  'Partial Delivered' = 'PartialDelivered',
  Refunded = 'Refunded',
  Accepted = 'Accepted'
}
export enum WalletStatus {
  // pending = 'Pending',
  // processing = 'Processing',
  // onHold = 'OnHold',
  // failed = 'Failed',
  // compeleted = 'Compeleted',
  // canceled = 'Canceled',
  // returned = 'Returned',
  // 'Partial Delivered' = 'PartialDelivered',
  // Refunded = 'Refunded',
  // Accepted = 'Accepted'
}
