import { Invoice, InvoiceData, Orders } from './../models/order';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as orderActions from '../actions/order.action';
import * as checkoutActions from '../actions/checkout.action';
import { Order } from '../models/order';
import { IResponse } from '../models/interfaces/response.model';
export const orderFeatureKey = 'orderModule';
export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  selectId: selectOrderId,
});
export function selectOrderId(x: Order): string {
  return x._id as string;
}
export interface FeatureState extends EntityState<Order> {
  getOrder: Order;
  message: string;
  updated: boolean;
  invoice: any;
  // checkout: Order | undefined;
  checkout: InvoiceData;
  completeOrder: any;
  getUserOrders: any;
  allOrders: Orders;
}

export interface AppState {
  orderModule: FeatureState;
}
export const selectFeature = (state: AppState) => state.orderModule;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

export const featureKeyOrder = createSelector(
  selectFeature,
  (state: FeatureState) => state
);
export const selectOrdersEntities = createSelector(featureKeyOrder, selectAll);
export const selectCountOrders = createSelector(featureKeyOrder, selectAll);
export const selectOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.getOrder
);
export const selectInvoice = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.invoice
);
export const selectAllOrders = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.allOrders
);
export const selectUserOrders = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.getUserOrders
);
export const selectUpdateOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.getOrder
);
export const selectDeleteOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state.getOrder
);
export const selectCheckout = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state?.checkout
);
export const selectCompletedOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state?.completeOrder
);

export const initialState: FeatureState = adapter.getInitialState({
  getOrder: {},
  message: '',
  invoice: {},
  updated: false,
  checkout: {},
  completeOrder: {},
  getUserOrders: {},
  allOrders: {},
});

const orderReducer = createReducer(
  initialState,
  on(orderActions.GET_ALL_ORDERS, (state) => {
    return adapter.removeAll(state);
  }),
  on(orderActions.GET_ALL_ORDERS_FAIL, (state) => ({ ...state })),
  on(orderActions.GET_ALL_ORDERS_SUCCESS, (state, action) => ({
    ...state,
    allOrders: action.payload,
  })),

  on(orderActions.COUNT_ORDERS, (state) => {
    return adapter.removeAll(state);
  }),
  on(orderActions.COUNT_ORDERS_FAIL, (state) => ({ ...state })),
  on(orderActions.COUNT_ORDERS_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),

  on(orderActions.GET_USER_ORDERS, (state) => ({ ...state })),
  on(orderActions.GET_USER_ORDERS_FAIL, (state) => ({ ...state })),
  on(orderActions.GET_USER_ORDERS_SUCCESS, (state, action) => ({
    ...state,
    getUserOrders: action.payload,
  })),

  on(orderActions.GET_ORDER, (state) => ({ ...state, getOrder: {} })),
  on(orderActions.GET_ORDER_FAIL, (state) => ({ ...state })),
  on(orderActions.GET_ORDER_SUCCESS, (state, action) => ({
    ...state,
    getOrder: action.payload,
  })),

  on(orderActions.GET_INVOICE, (state) => ({ ...state, invoice: {} })),
  on(orderActions.GET_INVOICE_FAIL, (state) => ({ ...state })),
  on(orderActions.GET_INVOICE_SUCCESS, (state, action) => ({
    ...state,
    invoice: action.payload,
  })),

  on(orderActions.CREATE_ORDER_NOTE, (state) => ({ ...state, getOrder: {} })),
  on(orderActions.CREATE_ORDER_NOTE_FAIL, (state) => ({ ...state })),
  on(orderActions.CREATE_ORDER_NOTE_SUCCESS, (state, action) => {
    return { ...state, getOrder: action.payload };
  }),

  on(orderActions.UPDATE_ORDER, (state) => ({
    ...state,
    getOrder: {},
    updated: false,
  })),
  on(orderActions.UPDATE_ORDER_FAIL, (state) => ({ ...state })),
  on(orderActions.UPDATE_ORDER_SUCCESS, (state, action) => {
    return { ...state, getOrder: action.payload, updated: true };
  }),

  on(checkoutActions.CHECKOUT, (state) => ({
    ...state,
    checkout: {},
  })),
  on(checkoutActions.CHECKOUT_FAIL, (state) => ({ ...state })),
  on(checkoutActions.CHECKOUT_SUCCESS, (state, action) => {
    return {
      ...state,
      checkout: action.payload,
    };
  }),

  on(checkoutActions.COMPLETE_ORDER, (state) => ({
    ...state,
    completeOrder: {},
    updated: false,
  })),
  on(checkoutActions.COMPLETE_ORDER_FAIL, (state) => ({ ...state })),
  on(checkoutActions.COMPLETE_ORDER_SUCCESS, (state, action) => {
    return {
      ...state,
      completeOrder: action.payload,
      updated: true,
    };
  }),

  on(orderActions.DELETE_ORDER, (state) => ({
    ...state,
    getOrder: {},
    updated: false,
  })),
  on(orderActions.DELETE_ORDER_FAIL, (state) => ({ ...state })),
  on(orderActions.DELETE_ORDER_SUCCESS, (state, action) => {
    return { ...state, getOrder: action.payload, updated: true };
  })
);
export function reducer(state: FeatureState, action: Action) {
  return orderReducer(state, action);
}
