import {
  InvoiceData,
} from '../models/order';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import * as checkoutActions from '../actions/checkout.action';
import { Order } from '../models/order';

export const checkoutFeatureKey = 'checkoutModule';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectCheckoutId,
});
export function selectCheckoutId(x: any): string {
  return x._id as string;
}

export interface FeatureState extends EntityState<Order> {
  checkout: InvoiceData;
  completeOrder: any;
  returnOrder: any;
  updated: boolean;
}

export interface AppState {
  checkoutModule: FeatureState;
}

export const selectFeature = (state: AppState) => state.checkoutModule;

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();

export const featureKeyOrder = createSelector(
  selectFeature,
  (state: FeatureState) => state,
);

export const selectCheckout = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state?.checkout,
);

export const selectCompletedOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state?.completeOrder,
);

export const selectReturnedOrder = createSelector(
  featureKeyOrder,
  (state: FeatureState) => state?.returnOrder,
);

export const initialState: FeatureState = adapter.getInitialState({
  updated: false,
  checkout: {},
  completeOrder: {},
  returnOrder: {},
});

const checkoutReducer = createReducer(
  initialState,
  
  on(
    checkoutActions.CHECKOUT,
    (state) => ({
      ...state,
      checkout: {},
    }),
  ),
  on(
    checkoutActions.CHECKOUT_FAIL,
    (state) => ({ ...state }),
  ),
  on(
    checkoutActions.CHECKOUT_SUCCESS,
    (state, action) => {
      return {
        ...state,
        checkout: action.payload,
      };
    },
  ),

  on(
    checkoutActions.COMPLETE_ORDER,
    (state) => ({
      ...state,
      completeOrder: {},
      updated: false,
    }),
  ),
  on(
    checkoutActions.COMPLETE_ORDER_FAIL,
    (state, action) => {
      return {
        ...state,
        completeOrder: action.payload,
        updated: false,
      };
    },
  ),
  on(
    checkoutActions.COMPLETE_ORDER_SUCCESS,
    (state, action) => {
      return {
        ...state,
        completeOrder: action.payload,
        updated: true,
      };
    },
  ),

  on(
    checkoutActions.RETURN_ORDER,
    (state) => ({
      ...state,
      returnOrder: {},
      updated: false,
    }),
  ),
  on(
    checkoutActions.RETURN_ORDER_FAIL,
    (state, action) => {
      return {
        ...state,
        returnOrder: action.payload,
        updated: false,
      };
    },
  ),
  on(
    checkoutActions.RETURN_ORDER_SUCCESS,
    (state, action) => {
      return {
        ...state,
        returnOrder: action.payload,
        updated: true,
      };
    },
  ),
);

export function reducer(
  state: FeatureState,
  action: Action,
) {
  return checkoutReducer(state, action);
}
