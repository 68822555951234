import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Roles } from '../models/enums/roles.enum';
import { AuthService } from '../services/auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardGuard  {
  constructor(private router: Router, private authServ: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { admin, employee1, employee2, employee3 } = Roles;
    const accessRoles: string[] = [admin, employee1, employee2, employee3];

    return this.authServ.validateAdmin().pipe(map((response) => {
      if (response) {
        return true;
      } else {
        this.router.navigateByUrl('/auth/login');
        return false;
      }
    }),
      catchError(() => {
        this.router.navigateByUrl('/auth/login');
        return of(false);
      })
    );
  }
}
