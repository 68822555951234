import { Action, createAction, props } from "@ngrx/store";
import { ICreateOrderNoteRequest } from "../models/interfaces/ICreateOrderNoteRequest";
import { Invoice, Order, Orders, InvoiceData, Count } from './../models/order';
/************* get all Orders ************************/
export const GET_ALL_ORDERS = createAction('[Orders List] getAllOrders', props<{ payload: string }>());
export const GET_ALL_ORDERS_SUCCESS = createAction('[Orders List] getAllOrders success', props<{ payload: Orders }>());
export const GET_ALL_ORDERS_FAIL = createAction('[Orders List] getAllOrders fail', props<{ payload: Array<any> }>());
/************* get Order by order id ************************/
export const GET_ORDER = createAction('[Order By Id] getOrder', props<{ payload: string }>());
export const GET_ORDER_SUCCESS = createAction('[Order By Id] getOrder success', props<{ payload: Order }>());
export const GET_ORDER_FAIL = createAction('[Order By Id] getOrder fail', props<{ payload: any }>());
/************* get Order by order id ************************/
export const GET_INVOICE = createAction('[get invoice] getInvoice', props<{ payload: string }>());
export const GET_INVOICE_SUCCESS = createAction('[get invoice] getInvoice success', props<{ payload: Invoice }>());
export const GET_INVOICE_FAIL = createAction('[get invoice] getInvoice fail', props<{ payload: any }>());
/************* get User Orders  ************************/
export const GET_USER_ORDERS = createAction('[Get User Orders] getUserOrders', props<{ payload: string }>());
export const GET_USER_ORDERS_SUCCESS = createAction('[Get User Orders] getUserOrders success', props<{ payload: Orders }>());
export const GET_USER_ORDERS_FAIL = createAction('[Get User Orders ] getUserOrders fail', props<{ payload: any }>());
/************* Checkout ************************/
export const CHECKOUT = createAction('[Checkout] checkout', props<{ payload: any }>());
export const CHECKOUT_SUCCESS = createAction('[Checkout] checkout success', props<{ payload: InvoiceData }>());
export const CHECKOUT_FAIL = createAction('[Checkout] checkout fail', props<{ payload: any }>());
/************* create order note ************************/
export const CREATE_ORDER_NOTE = createAction('[Create Order Note] order note', props<{ payload: ICreateOrderNoteRequest }>());
export const CREATE_ORDER_NOTE_SUCCESS = createAction('[Create Order Note] order note success', props<{ payload: Order }>());
export const CREATE_ORDER_NOTE_FAIL = createAction('[Create Order Note] order note fail', props<{ payload: any }>());
/************* create Order ************************/
export const Create_ORDER = createAction('[Create Order] createOrder', props<{ payload: Order }>());
export const Create_ORDER_SUCCESS = createAction('[Create Order] createOrder success', props<{ payload: Order }>());
export const Create_ORDER_FAIL = createAction('[Create Order] createOrder fail', props<{ payload: any }>());

export const UPDATE_ORDER = createAction('[Update Order] updateOrder', props<{ payload: Order }>());
export const UPDATE_ORDER_SUCCESS = createAction('[Update Order] updateOrder Success', props<{ payload: any }>());
export const UPDATE_ORDER_FAIL = createAction('[Update Order] updateOrder Fail', props<{ payload: any }>());

export const COMPLETE_ORDER = createAction('[complete Order] completeOrder', props<{ payload: string }>());
export const COMPLETE_ORDER_SUCCESS = createAction('[complete Order] completeOrder Success', props<{ payload: any }>());
export const COMPLETE_ORDER_FAIL = createAction('[complete Order] completeOrder Fail', props<{ payload: any }>());

export const DELETE_ORDER = createAction('[Delete Order] ', props<{ payload: string }>());
export const DELETE_ORDER_SUCCESS = createAction('[Delete Order]  Success', props<{ payload: any }>());
export const DELETE_ORDER_FAIL = createAction('[Delete Order]  Fail', props<{ payload: any }>());

export const COUNT_ORDERS = createAction('[count orders] countOrders')
export const COUNT_ORDERS_SUCCESS = createAction('[count orders] countOrders success', props<{ payload: Array<Count> }>())
export const COUNT_ORDERS_FAIL = createAction('[count orders] countOrders fail', props<{ payload: any }>())
export interface ActionWithPayload<T> extends Action { payload: T; }
