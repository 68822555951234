<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-3" routerLink="employee">
      <div class="card mt-4 shadow d-employee">
        <div class="card-body">
          <h5 class="card-title">Employees</h5>
          <p class="card-text">
            <i style="font-size: x-large" class="pi pi-sitemap"></i>
          </p>
          <p class="card-text">{{ 10 }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3" routerLink="resellers">
      <div class="card mt-4 shadow d-users">
        <div class="card-body">
          <h5 class="card-title">Resellers</h5>
          <p class="card-text">
            <i style="font-size: x-large" class="pi pi-users"></i>
          </p>
          <p class="card-text">{{ 100 }}</p>
        </div>
      </div>
    </div>
  </div>
</div>