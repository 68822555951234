<div class="header d-flex justify-content-between align-items-center">
  <!-- <div class="col-3">
    <button class="btn toggle-btn" (click)="triggerEvent()">
      <i class="pi pi-align-justify"></i>
    </button>
  </div> -->
  <div class="col-12 d-flex justify-content-end align-items-center">
    <i class="pi pi-search mr-3"></i>
    <i class="pi pi-bell mr-3"></i>
    <ng-template #auth>
      <a routerLink="/panel/setting/profile">
        <div *ngIf="!user.avatar || loader" class="bg-light rounded-circle d-flex align-items-center justify-content-center mr-5" style="width: 26px; height: 26px;"><i class="pi pi-user text-light-emphasis"></i></div>
        <img [src]="userAvatar ? userAvatar : user.avatar " *ngIf="user.avatar && !loader" alt="avatar" class="rounded-circle mr-5" style="width: 26px; height: 26px;">
      </a>
    </ng-template>
    <ng-template #notAuth>
      <button class="btn btn-primary mr-3" routerLink="/auth/login">
        Login/Register
      </button>
    </ng-template>
    <ng-container *ngIf="user && user._id; then auth; else notAuth"></ng-container>
  </div>
</div>