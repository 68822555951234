import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as productActions from '../actions/product.action';
import { LicenseStatus } from '../models/enums/licenseStatus.enum';
import { LicenseRes, Licenses } from '../models/license';
import { Count } from '../models/order';
export const licensesFeatureKey = 'licenseModule';
export const adapter: EntityAdapter<LicenseRes> = createEntityAdapter<LicenseRes>({ selectId: selectLicenseId });
export function selectLicenseId(a: LicenseRes): string { return a._id as string; }
export interface FeatureState extends EntityState<LicenseRes> {
  addedLicenses: LicenseRes[];
  deletedLicenses: LicenseRes[];
  getLicense: Licenses;
  countLicenses: Count[];
}
export interface AppState { licenseModule: FeatureState; }
export const selectFeature = (state: AppState) => state.licenseModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureLicenses = createSelector(selectFeature, (state: FeatureState) => state);
export const selectAllLicenses = createSelector(selectFeatureLicenses, selectEntities);
export const selectAllLicensesEntities = createSelector(selectFeatureLicenses, selectAll);
export const selectDeletedLicenses = createSelector(selectFeatureLicenses, (state: FeatureState) => state.deletedLicenses);
export const selectAddedLicenses = createSelector(selectFeatureLicenses, (state: FeatureState) => state.addedLicenses);
export const selectLicense = createSelector(selectFeatureLicenses, (state: FeatureState) => state.getLicense);
export const selectCountLicenses = createSelector(selectFeatureLicenses, (state: FeatureState) => state.countLicenses);

export const initialState: FeatureState = adapter.getInitialState({
  addedLicenses: [],
  deletedLicenses: [],
  getLicense: {},
  countLicenses: [],
});
let deletedLicenses: string[], productId: string;
const licensesReducer = createReducer(
  initialState,
  on(productActions.onGetAllLicenses, (state, action) => {
    productId = action.payload?.split('&')[0].split('=')[1];
    return adapter.removeAll(state);
  }),
  on(productActions.onGetAllLicensesFailed, (state) => ({ ...state })),
  on(productActions.onGetAllLicensesSucceed, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),

  on(productActions.onGetDeletedLicenses, (state) => ({ ...state })),
  on(productActions.onGetDeletedLicensesFailed, (state) => ({ ...state })),
  on(productActions.onGetDeletedLicensesSucceed, (state, action) =>  {
    return { ...state, deletedLicenses: action.payload };
  }),

  on(productActions.onAddLicense, (state) => ({ ...state, addedLicenses: [] })),
  on(productActions.onAddLicenseFailed, (state) => ({ ...state })),
  on(productActions.onAddLicenseSucceed, (state, action) => {
    return adapter.addMany(action.payload, { ...state, addedLicenses: action.payload });
  }),

  on(productActions.onGetLicense, (state) => ({ ...state, getLicense: {} })),
  on(productActions.onGetLicenseFailed, (state) => ({ ...state, getLicense: {} })),
  on(productActions.onGetLicenseSucceed, (state, action) =>  {
    return { ...state, getLicense: action.payload };
  }),

  on(productActions.COUNT_LICENSES, (state) => ({ ...state })),
  on(productActions.COUNT_LICENSES_FAIL, (state) => ({ ...state })),
  on(productActions.COUNT_LICENSES_SUCCESS, (state, action) =>  {
    return { ...state, countLicenses: action.payload };
  }),

  on(productActions.onDeleteLicenses, (state, action) => {
    deletedLicenses = [...new Set(action.payload)];
    return state;
  }),
  on(productActions.onDeleteLicensesFailed, (state) => ({ ...state })),
  on(productActions.onDeleteLicensesSucceed, (state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    newState.countLicenses.map((item: any) => {
      if (item._id === LicenseStatus.deleted) {
        item.count += deletedLicenses.length;
        return item;
      }
    });
    return adapter.removeMany(deletedLicenses, newState);
  }),
);
export function reducer(state: FeatureState, action: Action) { return licensesReducer(state, action); }
