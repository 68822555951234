import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { IUser } from 'projects/shared/models/interfaces/user.model';
import { Subscription } from 'rxjs';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';
import { ProfileService } from '../../panel/setting/services/profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user!: StaffLoginResponse;
  userAvatar!: string;
  subscription: Subscription = new Subscription();
  @Output() event: EventEmitter<boolean> = new EventEmitter();
  toggle = true;
  loader = false;
  constructor(private storeChangesServ: StoreChangesService, private profileServ: ProfileService) { }

  ngOnInit(): void {
    this.getUser();
    this.profileServ.userData.subscribe(
      res => {
        if (res.avatar) this.userAvatar = res.avatar!
      }
    )

    this.profileServ.avatarLoader.subscribe(
      res => {
        this.loader = res;
      }
    )
  }
  getUser() {
    this.subscription.add(
      this.storeChangesServ.getUserAdmin().subscribe({
        next: (response) => {
          this.user = response;
        },
      })
    );
  }

  triggerEvent(): void {
    this.toggle = !this.toggle;
    this.event.emit(this.toggle);
  }
}
