import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as orderActions from '../actions/order.action';
import { ICreateOrderNoteRequest } from '../models/interfaces/ICreateOrderNoteRequest';
import { Order } from '../models/order';
import { OrderService } from '../services/order.service';
@Injectable()
export class OrderEffects {
  constructor(private actions$: Actions, private orderService: OrderService) { }
  public loadAllOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_ALL_ORDERS.type),
    mergeMap((action: orderActions.ActionWithPayload<string>) => {
      return this.orderService.getOrders().pipe(
        retry(1),
        map((response) => { return orderActions.GET_ALL_ORDERS_SUCCESS({ payload: response }) }),
        catchError((error) => of(orderActions.GET_ALL_ORDERS_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadAllOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_ALL_ORDERS_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => {return action.payload})),
    { dispatch: false },
  );
  public loadAllUserOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_USER_ORDERS.type),
    mergeMap((action: orderActions.ActionWithPayload<string>) => {
      return this.orderService.getUserOrders(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.GET_USER_ORDERS_SUCCESS({ payload: response }) }),
        catchError((error) => of(orderActions.GET_USER_ORDERS_FAIL({ payload: error }))),
      );
    })
  ));
  public errorOnLoadAllUserOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_USER_ORDERS_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public loadOrderById$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_ORDER.type),
    mergeMap((action: orderActions.ActionWithPayload<string>) => {
      return this.orderService.getOrder(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.GET_ORDER_SUCCESS({ payload: response }) }),
        catchError((error) => of(orderActions.GET_ORDER_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadOrderById$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_ORDER_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public loadInvoiceByOrderId$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_INVOICE.type),
    mergeMap((action: orderActions.ActionWithPayload<string>) => {
      return this.orderService.getInvoice(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.GET_INVOICE_SUCCESS({ payload: response }) }),
        catchError((error) => of(orderActions.GET_INVOICE_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadInvoiceByOrderId$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.GET_INVOICE_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public createOrderNote$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.CREATE_ORDER_NOTE.type),
    mergeMap((action: orderActions.ActionWithPayload<ICreateOrderNoteRequest>) => {
      return this.orderService.createOrderNote(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.CREATE_ORDER_NOTE_SUCCESS({ payload: response }); }),
        catchError((error) => of(orderActions.CREATE_ORDER_NOTE_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnCreateOrderNote$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.CREATE_ORDER_NOTE_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public CountOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.COUNT_ORDERS.type),
    mergeMap((action: orderActions.ActionWithPayload<any>) => {
      return this.orderService.countOrders().pipe(
        retry(1),
        map((response) => { return orderActions.COUNT_ORDERS_SUCCESS({ payload: response }); }),
        catchError((error) => of(orderActions.COUNT_ORDERS_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorOnCountOrders$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.COUNT_ORDERS_FAIL.type),
    tap((action: orderActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );

  public updateOrder$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.UPDATE_ORDER.type),
    mergeMap((action: orderActions.ActionWithPayload<Order>) => {
      return this.orderService.updateOrder(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.UPDATE_ORDER_SUCCESS({ payload: response }); }),
        catchError((error) => of(orderActions.UPDATE_ORDER_FAIL({ payload: error }))),
      );
    }),
  ));
  
  public deleteOrder$ = createEffect(() => this.actions$.pipe(
    ofType(orderActions.DELETE_ORDER.type),
    mergeMap((action: orderActions.ActionWithPayload<string>) => {
      return this.orderService.deleteOrder(action.payload).pipe(
        retry(1),
        map((response) => { return orderActions.DELETE_ORDER_SUCCESS({ payload: response }); }),
        catchError((error) => of(orderActions.DELETE_ORDER_FAIL({ payload: error }))),
      );
    }),
  ));
}
