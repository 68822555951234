import { BehaviorSubject } from "rxjs"

export const w1200 = new BehaviorSubject<boolean>(false);


window.addEventListener('resize', e => {
  if (window.innerWidth <= 1200) w1200.next(true);
  else w1200.next(false);
})



